var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card card-custom shadow-sm mb-5"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v(" "+_vm._s(_vm.$t("Litigation"))+" ")]),_c('span',{class:[
            'ml-3 label label-xl font-weight-bold label-inline label-square',
            _vm.litigation.opened && 'label-dark',
            _vm.litigation.handled && 'label-success'
          ]},[_vm._v(" "+_vm._s(_vm.litigation.status)+" ")]),(_vm.litigation.handled)?_c('span',{class:[
            'ml-3 label label-xl font-weight-bold label-inline label-square',
            _vm.litigation.approved && 'label-success',
            _vm.litigation.canceled && 'label-danger'
          ]},[_vm._v(" "+_vm._s(_vm.litigation.decision)+" ")]):_vm._e()])]),_c('div',{staticClass:"card-body pt-2"},[_c('h3',[_vm._v(_vm._s(_vm.litigation.title))]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.litigation.content)}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }