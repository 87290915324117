var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.message.showDate)?_c('div',{staticClass:"text-center m-2"},[_c('span',{staticClass:"font-size-h5 text-dark-65 font-weight-bold"},[_vm._v(_vm._s(_vm.message.dateDisplay))])]):_vm._e(),_c('div',{class:[
      'd-flex flex-column mb-0',
      _vm.message.fromCurrentAccount && 'align-items-end',
      !_vm.message.fromCurrentAccount && 'align-items-start'
    ]},[_c('div',{class:[
        'mt-2 p-4 font-weight-bold font-size-lg max-w-400px ',
        _vm.message.fromCurrentAccount && 'bg-light text-dark-65',
        !_vm.message.fromCurrentAccount && 'bg-dark text-white'
      ]},[(!_vm.message.isFile)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.message.content)}}):_vm._e(),(_vm.message.isFile)?_c('div',{staticClass:"text-hover-success cursor-pointer",on:{"click":function($event){return _vm.downloadFile(_vm.message.fileName, _vm.message.fileUrl)}}},[_c('i',{staticClass:"fas fa-file-download mr-1"}),_vm._v(" "+_vm._s(_vm.message.fileName)+" "),_c('br'),_c('p',{class:[
            'm-0',
            _vm.message.fromCurrentAccount && 'text-right',
            !_vm.message.fromCurrentAccount && 'text-left'
          ]},[_c('span',[_vm._v(_vm._s(_vm.message.fileSize))])])]):_vm._e(),_c('span',{staticClass:"text-muted font-size-sm"},[_vm._v(_vm._s(_vm.message.time))]),_c('button',{ref:'btn-mark-' + _vm.message.id,class:[
          'btn btn-icon btn-xs btn-hover-text-warning float-right active ml-4'
        ],attrs:{"data-toggle":"tooltip","data-placement":"right","title":"Mark","data-original-title":"Star"},on:{"click":function($event){return _vm.markUnmarkChatMessage(_vm.message.id)}}},[_c('i',{ref:'icon-star-' + _vm.message.id,class:['flaticon-star', _vm.markedClass]})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }